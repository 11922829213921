<ng-container *transloco="let t; read: 'web'">
  <div
    class="sticky z-10 flex h-[3.75rem] w-auto max-w-full items-center border-b surface-neutral-light border-neutral-default"
  >
    <!-- left side -->
    @if (isChaincargoPlatform) {
      @if (navService) {
        <div class="pl-6">
          <button
            mat-icon-button
            type="button"
            color="primary"
            aria-label="Back button"
            (click)="navService.back()"
          >
            <cca-icon
              class="text-2xl leading-4 text-neutral-body"
              icon="arrow-left"
            ></cca-icon>
          </button>
        </div>
      }
      @if (headerService) {
        <div class="flex flex-row items-center gap-2 pl-1">
          @let headerData = headerService();
          @if (headerData) {
            @if (isTranslateHeaderData(headerData)) {
              <h1 class="text-neutral-body">
                <ng-container
                  *ngTemplateOutlet="title; injector: dynamicInjector()"
                ></ng-container>
                <ng-template #title>
                  {{
                    headerData.translationKey
                      | transloco: headerData.translocoParams
                  }}
                </ng-template>
              </h1>
            } @else {
              <h1 class="text-neutral-body">{{ headerData.title }}</h1>
            }

            @if (headerData.badge) {
              <cca-numerical-badge
                [flavor]="headerData.flavor ?? 'highlight'"
                [number]="headerData.badge"
                [smallBadge]="true"
                class="flex h-full items-center"
              ></cca-numerical-badge>
            }
          }
        </div>
      }
      <ng-content select="[ccaHeaderLeft], [cca-header-left]"></ng-content>
    }

    <ng-content></ng-content>

    <!-- right side -->
    @if (isChaincargoPlatform) {
      <div class="ml-auto mr-5 flex h-full items-center">
        @if (devMenuEnabled()) {
          @defer {
            <cca-dev-menu-toggle></cca-dev-menu-toggle>
            <span
              class="ml-2 mr-2 block h-6 border-l border-neutral-default"
            ></span>
          }
        }

        @if (environment.enableHubspot && hubspotExist) {
          <button color="primary" mat-button (click)="onContactSupport()">
            {{ t('contactSupport') }}
          </button>
          <span
            class="ml-2 mr-2 block h-6 border-l border-neutral-default"
          ></span>
        }
        <ng-content select="[ccaHeaderRight], [cca-header-right]"></ng-content>

        <button
          mat-icon-button
          (click)="notificationPanelToggle.emit()"
          matBadgeColor="warn"
          matBadgePosition="after"
          matBadgeSize="medium"
          [matBadgeOverlap]="true"
          [matBadgeHidden]="!notificationsStore.unreadNotificationsCount()"
          [matBadge]="notificationsStore.unreadNotificationsCount()"
        >
          <cca-icon class="text-neutral-subtitle" icon="bell"></cca-icon>
        </button>

        <span
          class="ml-2 mr-4 block h-6 border-l border-neutral-default"
        ></span>

        <cca-language-switch></cca-language-switch>

        <span class="mx-4 block h-6 border-l border-neutral-default"></span>

        <cca-header-menu></cca-header-menu>
      </div>
    }
  </div>
</ng-container>
